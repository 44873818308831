<div class="pop-up">
  <div class="pop-up__container">
    <div class="pop-up__content">
      <p class="pop-up__text text">
        Hey Champ! Thanks for signing up. Once the tool is available for use, we
        will notify you by email.
      </p>
    </div>
    <div class="pop-up__btn">
      <button (click)="onClose()" class="btn-price" type="button">Close</button>
    </div>
  </div>
</div>
